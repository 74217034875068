













































































































import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUsersStore } from '@/store/users-store';
import { Employee } from '@/model/employee';
import TextInput from '@/components/shared/forms/TextInput.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import CheckboxInput from '@/components/shared/forms/CheckboxInput.vue';
import { useEmployeesStore } from '@/store/employees-store';
import { User } from '@/model/user';
import { Service } from '@/model/service';
import SelectServicesModal from '@/components/shared/SelectServicesModal.vue';
import Alert from '@/components/shared/Alert.vue';
import { AlertType } from '@/store/ui-store';

export default Vue.extend({
  components: {
    CheckboxInput,
    TextInput,
    SubmitButton,
    SelectServicesModal,
    Alert
  },
  data() {
    return {
      form: {
        limit_timetable_forward_months_visibility: false as boolean,
        timetable_forward_months_visibility: null as number,
        limit_max_services_per_day: false as boolean,
        max_services_per_day: [] as Array<Service & { max_per_day: number }>
      },
      sendingData: false as boolean,
      AlertType: AlertType
    };
  },
  computed: {
    ...mapStores(useUsersStore, useEmployeesStore),
    user(): User {
      return this.usersStore.user.entity;
    },
    employee(): Employee {
      return this.employeesStore.getById(this.user.employee.id);
    },
    servicesWithMaxPerDay(): Service[] {
      return this.employee.services.filter(
        service => service._joinData.max_per_day !== null
      );
    },
    services(): Service[] {
      return this.employee.services;
    },
    validationErrors(): any {
      return this.employeesStore.validationErrors;
    }
  },
  mounted() {
    if (!this.employee) {
      const unsubscribe = this.employeesStore.$subscribe(() => {
        if (this.employeesStore.entities.length > 0) {
          this.setupForm();
          unsubscribe();
        }
      });
    } else {
      this.setupForm();
    }
  },
  methods: {
    setupForm() {
      this.form.timetable_forward_months_visibility = this.employee.timetable_forward_months_visibility;
      if (this.employee.timetable_forward_months_visibility) {
        this.form.limit_timetable_forward_months_visibility = true;
      }
      this.addServices(this.servicesWithMaxPerDay);
      this.form.limit_max_services_per_day =
        this.servicesWithMaxPerDay.length > 0;
    },
    toggleLimitTimetableForwardMonthsVisibility() {
      this.form.limit_timetable_forward_months_visibility = !this.form
        .limit_timetable_forward_months_visibility;
      if (!this.form.limit_timetable_forward_months_visibility) {
        // @ts-ignore
        this.form.timetable_forward_months_visibility = '';
      }
    },
    toggleLimitMaxServicesPerDay() {
      this.form.limit_max_services_per_day = !this.form
        .limit_max_services_per_day;
    },
    openServicesModal() {
      this.$bvModal.show('select-services');
    },
    deleteService(serviceIndex: number) {
      this.form.max_services_per_day.splice(serviceIndex, 1);
    },
    addServices(services: Service[]) {
      services.forEach(service => {
        this.form.max_services_per_day.push({
          ...service,
          max_per_day:
            service._joinData?.max_per_day >= 0
              ? service._joinData?.max_per_day
              : 1
        });
      });
    },
    async saveSettings() {
      const servicesDto = this.employee.services.map((service: Service) => {
        const serviceWithMaxPerDayFrom = this.form.max_services_per_day.find(
          loopService => loopService.id === service.id
        );
        const serviceFromEmployeesState: Service = this.employee.services.find(
          loopService => loopService.id === service.id
        );
        const serviceDto = {
          id: service.id,
          _joinData: {
            preference: serviceFromEmployeesState._joinData.preference,
            max_per_day: null
          }
        };
        if (serviceWithMaxPerDayFrom) {
          serviceDto._joinData.max_per_day =
            serviceWithMaxPerDayFrom.max_per_day;
        }
        return serviceDto;
      });

      this.sendingData = true;
      await this.employeesStore.edit({
        id: this.employee.id,
        timetable_forward_months_visibility: this.form
          .timetable_forward_months_visibility,
        lastname: this.user.lastname,
        firstname: this.user.firstname,
        // @ts-ignore
        role: this.user.role,
        email: this.user.email,
        // @ts-ignore
        services: servicesDto
      });
      await this.usersStore.getUser(this.user.id);
      this.sendingData = false;
    }
  }
});
